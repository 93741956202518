import { AccessAlarm, PriorityHigh, Warning } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import React from "react";

export default function CardDateTimeIndicator({ type, text }) {

    var cor = 'green'
    var atrasado = false
    var atencao = false
    var normal = false
    switch (type) {
        case 'danger': {
            cor = 'red'
            atrasado = true
            break
        }
        case 'warning': {
            cor = 'yellow'
            atencao = true
            break
        }
        default: {
            cor = 'green'
            normal = true
        }
    }

    return (
        <Box 
            sx={{ 
                backgroundColor: cor, 
                mr: 1, 
                ml: 1,
                padding: 1,
                borderRadius: 2  
            }}
        >                                    
            <IconButton edge='start' aria-label='info' sx={{ ml: 0.2 }}>
                {
                    atrasado && <PriorityHigh sx={{ color: 'white' }} fontSize="small" />
                }
                {
                    atencao && <Warning sx={{ color: 'black' }} />
                }
                {
                    normal && <AccessAlarm sx={{ color: 'white' }} />
                }
            </IconButton>
            <span>{text}</span>
        </Box>
    )
}