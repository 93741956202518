import { Box, List, Paper, Typography } from "@mui/material";
import Cartao from "./Cartao";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../routes/Funil";


const Lista = ({ lista, moveCard, token, fetchListas }) => {
    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item) {
            if (item.lista.id !== lista.id) {
                moveCard(item.index, item.lista.id, lista.id);
                item.index = lista.cards.length; // Move to the end of the new column
                item.lista = lista;
            }
        },
    });

    return (
        // '#D3D3D3'
        <Box sx={{ margin: '2px', padding: '2px' }}>
            <Typography variant="h6" sx={{ fontFamily: '"Helvetica Neue", Arial, sans-serif', textAlign: 'center' }} >{lista.nome}</Typography>
            <Paper ref={drop} sx={{ margin: '16px', padding: '16px', minWidth: '260px', backgroundColor: '#f8f8f8', borderRadius: '5px', maxHeight: 840, overflow: 'auto' }} elevation={3}>
                
                <List>
                    {
                        lista.cards ? (
                            lista.cards.sort((a, b) => b.rating - a.rating).map((card, index) => (
                                <Cartao
                                    key={card.id}
                                    cartao={card}
                                    index={index}
                                    moveCard={moveCard}
                                    lista={lista}
                                    token={token}
                                    fetchListas={fetchListas}
                                />
                            ))
                        ) : (
                            <></>
                        )
                    }
                </List>
            </Paper>
        </Box>

    );
};

export default Lista;