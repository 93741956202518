const horaInicio = 9; // Hora de início do trabalho
const horaFim = 17; // Hora de término do trabalho

function getRandomDate() {
    
    const minTimestamp = new Date('2024-06-01').getTime(); // Data atual
    const maxTimestamp = new Date('2024-07-01').getTime();

    const randomTimestamp = Math.random() * (maxTimestamp - minTimestamp) + minTimestamp;
    const randomDate = new Date(randomTimestamp);

    return randomDate;
}

function adicionarHorasUteis(dataInicial, horasUteis) {
    const data = new Date(dataInicial); // Cria um novo objeto Date para evitar modificar o original
    const horaInicio = 9; // Hora de início do trabalho
    const horaFim = 17; // Hora de término do trabalho
    const horasPorDia = horaFim - horaInicio; // Total de horas úteis por dia

    while (horasUteis > 0) {
        const diaSemana = data.getDay();
        
        // Se for final de semana, pular para a próxima segunda-feira
        if (diaSemana === 6) { // Sábado
            data.setDate(data.getDate() + 2); // Pula para segunda-feira
            data.setHours(horaInicio);
        } else if (diaSemana === 0) { // Domingo
            data.setDate(data.getDate() + 1); // Pula para segunda-feira
            data.setHours(horaInicio);
        } else {
            // Calcular horas restantes no dia útil atual
            const horasRestantesNoDia = horaFim - data.getHours();
            if (horasUteis <= horasRestantesNoDia) {
                data.setHours(data.getHours() + horasUteis);
                horasUteis = 0;
            } else {
                data.setHours(horaFim); // Ir para o final do dia útil
                horasUteis -= horasRestantesNoDia;
                data.setDate(data.getDate() + 1); // Passar para o próximo dia
                data.setHours(horaInicio); // Início do próximo dia útil
            }
        }
    }

    return data;
}


// function adicionarHorasUteis(dataInicial, horasUteis) {
//     const data = new Date(dataInicial); // Cria um novo objeto Date para evitar modificar o original
//     let horasAdicionadas = 0;

//     while (horasAdicionadas < horasUteis) {
//         data.setHours(data.getHours() + 1);

//         const diaSemana = data.getDay();
//         if (diaSemana >= 1 && diaSemana <= 5) { // 1 = Segunda-feira, 5 = Sexta-feira
//             horasAdicionadas++;
//         }

//         // Se passar para o próximo dia, deve zerar as horas se necessário
//         if (data.getHours() >= 24) {
//             data.setHours(0);
//             data.setDate(data.getDate() + 1);
//         }

//         // Se cair no sábado, pular para segunda-feira
//         if (data.getDay() === 6) { // Sábado
//             data.setDate(data.getDate() + 2); // Pula para segunda-feira
//             data.setHours(0);
//         }

//         // Se cair no domingo, pular para segunda-feira
//         if (data.getDay() === 0) { // Domingo
//             data.setDate(data.getDate() + 1); // Pula para segunda-feira
//             data.setHours(0);
//         }
//     }

//     return data;
// }

// Exemplo de uso:
// const dataInicial = new Date('2024-06-05T09:00:00'); // Uma quarta-feira às 09:00
// const horasUteis = 10;

// const novaData = adicionarHorasUteis(dataInicial, horasUteis);
// console.log(novaData); // Output dependerá do número de horas úteis adicionadas

export { getRandomDate, adicionarHorasUteis };
