import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CurrencyTextField from '../CurrencyTextField';

const CurrencyEditableLabel = ({ initialText, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const formatCurrency = (value) => {
    const formattedValue = parseFloat(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
    return formattedValue;
  };

  return (
    <Box>
      {isEditing ? (
        <CurrencyTextField
          value={text}
          onChange={handleTextChange}
          onBlur={handleBlur}
          autoFocus
          variant="standard"
        />
      ) : (
        <Typography onClick={handleTextClick} sx={{ cursor: 'pointer', fontWeight: 'bold' }}>
          {text ? formatCurrency(text) : ''}
        </Typography>
      )}
    </Box>
  );
};

export default CurrencyEditableLabel;
