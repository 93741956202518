import React, { useState } from 'react';
import { Box, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const StarRating = ({ maxStars = 3, stars = 0, onRatingChange, starColor }) => {
  const [rating, setRating] = useState(stars);

  const handleRating = (index) => {
    const newRating = index + 1;
    setRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <Box display="flex">
      {[...Array(maxStars)].map((_, index) => (
        <IconButton key={index} onClick={() => handleRating(index)}>
          {index < rating ? <StarIcon sx={{ color: starColor }} /> : <StarBorderIcon sx={{ color: starColor }} />}
        </IconButton>
      ))}
    </Box>
  );
};

export default StarRating;
