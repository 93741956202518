import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Person } from '@mui/icons-material';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../../images/logo-octopuspipe-color.png';
import { withRouter } from '../../helpers/withRouter';

class HomeBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElNav: null,
      anchorElUser: null,
      pages: [],
      settings: []
    };

    // console.log('props no constructor: ', props)
    this.handleOpenNavMenu = this.handleOpenNavMenu.bind(this);
    this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this);
    this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);
    this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    this.handleNavMenuClick = this.handleNavMenuClick.bind(this);
    this.handleUserMenuClick = this.handleUserMenuClick.bind(this);
  }

  componentDidMount() {
    // console.log('props in homebar: ', this.props)
    const { profile } = this.props;

    if (profile.admin) {
      this.setState({
        pages: ['Assinantes'],
        settings: ['Alterar senha', 'Logout']
      });
    } else {
      this.setState({
        pages: ['Potenciais Clientes', 'Funil', 'Biblioteca'],
        settings: ['Alterar senha', 'Logout']
      });
    }
  }

  handleOpenNavMenu(event) {
    this.setState({ anchorElNav: event.currentTarget });
  }

  handleOpenUserMenu(event) {
    this.setState({ anchorElUser: event.currentTarget });
  }

  handleCloseNavMenu() {
    this.setState({ anchorElNav: null });
  }

  handleCloseUserMenu() {
    this.setState({ anchorElUser: null });
  }

  handleNavMenuClick(event, key) {
    this.setState({ anchorElNav: null });
    const { pages } = this.state;
    const { nome, token, profile, isAuthenticated, navigate } = this.props;
    const rota = pages[key].toLowerCase();

    // console.log('Vai navegar: ', nome, token, profile, isAuthenticated)
    switch (rota) {
      case 'potenciais clientes': 
        navigate(`/contatos`, { state: { nome, token, profile, isAuthenticated } });
        break;
      default: navigate(`/${rota}`, { state: { nome, token, profile, isAuthenticated } });
    }
  }

  handleUserMenuClick(event, key) {
    this.setState({ anchorElUser: null });
    const { settings } = this.state;
    const { nome, token, profile, isAuthenticated, navigate } = this.props;
    const rota = settings[key].toLowerCase();

    switch (rota) {
      case 'logout': {
        navigate('/');
        break;
      }
      case 'alterar senha': {
        navigate('/alterarsenha', { state: { nome, token, profile, isAuthenticated }});
        break;
      }
      default:
        navigate('/');
    }
  }

  render() {
    const { nome } = this.props;
    const { anchorElNav, anchorElUser, pages, settings } = this.state;

    return (
      // <AppBar position="static">
      <AppBar position="fixed" sx={{ width: '100%', overflowX: 'auto'}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box component='img' src={Logo} sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, maxWidth: 24, maxHeight: 24, width: '100%', height: '100%' }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              OctopusPipe
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={this.handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page, key) => (
                  <MenuItem key={key} onClick={event => this.handleNavMenuClick(event, key)}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box component='img' src={Logo} sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, maxWidth: 24, maxHeight: 24, width: '100%', height: '100%' }} />
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              OctopusPipe
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, key) => (
                <Button
                  key={key}
                  onClick={event => this.handleNavMenuClick(event, key)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={nome}>
                <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={nome}><Person /></Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={this.handleCloseUserMenu}
              >
                {settings.map((setting, key) => (
                  <MenuItem key={key} onClick={event => this.handleUserMenuClick(event, key)}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withRouter(HomeBar);
