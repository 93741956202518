import { Box, Container, TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import React, { useRef, useState } from "react";
import { newNormalUser } from "../services/SrvLogin";
// import GoogleLogo from '../images/web_neutral_rd_na.svg'
import { useNavigate } from "react-router-dom";

function Register() {

    const [senha, setSenha] = useState('')
    const [confirma, setConfirma] = useState('')
    const [erroSenha, setErroSenha] = useState(false)
    const [helperSenha, setHelperSenha] =useState('')
    const [snackSuccessOpen, setSnackSuccessOpen] = useState(false)
    const [snackFailedOpen, setSnackFailedSOpen] = useState(false)
    const [erro, setErro] = useState('')

    const senhaRef = useRef()
    const confirmaRef = useRef()

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(data.entries());

        const dados = {
            'username': formJson['email'],
            'nome': formJson['nome'],
            'senha': formJson['senha'],
            'sobrenome': formJson['sobrenome'],
            'email': formJson['email'],
            'codigo': formJson['codigo']
        }

        newNormalUser(dados)
            .then((response) => {
                if (response['token']) {
                    setSnackSuccessOpen(true)
                }
            }, 
                (error) => {
                    setSnackFailedSOpen(true)
                    setErro(`Ocorreu um erro ao cadastrar o usuário: ${error['response']['data']}`)
                    // console.log('Erro ao incluir novo usuário: ', error)
                }
            ) 
    }

    const handleSenhaChange = e => {

        if (e.target.name === 'senha') {
            setSenha(e.target.value)
        } else {
            setConfirma(e.target.value)
        }

        if (senha !== confirma) {
            // console.log('campos de senha diferentes')
            setErroSenha(true)
            setHelperSenha('Senha e confirmação devem ser iguais')
        } else {
            // console.log('campos de senha iguais')
            setErroSenha(false)
            setHelperSenha('')
        }
    }

    const handSuccessClose = e => {
        navigate('/')
    }

    const handErrorClose = e => {
        setSnackFailedSOpen(false)
    }

    return(
        <Container component='main' maxWidth='xs'>
            <Box sx={{ marginTop:8, display:'flex', flexDirection:'column', alignItems:'center' }}>
                <Typography component='h1' variant="h5">
                    Cadastrar
                </Typography>
                <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField required label='Nome' id="nome" name="nome" type="text" margin="normal" size="150" fullWidth autoFocus/>
                    <TextField required label='Sobrenome' id="sobrenome" name="sobrenome" type="text" margin="normal" fullWidth />
                    <TextField required label='Email' id="email" name="email" type="email" margin="normal" fullWidth />
                    <TextField required label='Senha' id="senha" name="senha" ref={senhaRef} 
                        type="password" 
                        margin="normal" 
                        fullWidth  
                        onBlur={handleSenhaChange}
                        onChange={handleSenhaChange}
                        onKeyUp={handleSenhaChange}
                        error={erroSenha}
                        helperText={helperSenha}
                    />
                    <TextField required label="Confirme a Senha" ref={confirmaRef} id="confirma" name="confirma" 
                        type="password" 
                        margin="normal" 
                        fullWidth  
                        onBlur={handleSenhaChange}
                        onChange={handleSenhaChange}
                        onKeyUp={handleSenhaChange}
                        error={erroSenha}
                        helperText={helperSenha}
                    />
                    <TextField required label='Código do Assinante' id="codigo" name="codigo" margin="normal" fullWidth />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} >Ir</Button>
                    <Snackbar open={snackSuccessOpen} autoHideDuration={6000} onClose={handSuccessClose}>
                        <Alert
                            onClose={handSuccessClose}
                            severity="success"
                            variant="filled"
                            sx={{width: '100%'}}
                        >
                            Usuário criado com sucesso. Faça login com email e senha informados.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={snackFailedOpen} autoHideDuration={6000} onClose={handErrorClose}>
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{width: '100%'}}
                        >
                            {erro}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
            {/* <Box component='div' maxWidth='xs' sx={{ marginTop: 1, display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography variant="caption" display="block" gutterBottom>
                Ou cadastre-se com o Google
                </Typography>
                <Button>
                    <img src={GoogleLogo} alt="Login com o Google" />
                </Button>
            </Box> */}
        </Container>
    )
}

export default Register;