import { AccountCircle, Email as EmailIcon, LocationCity } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import TelefoneEdit from "../TelefoneEdit";
import { getContato, newContato } from "../../services/SrvContato";

export default function ContatoForm({ chave, perfil, setAlert, fetchListas, fetchContatos, addCard }) {

    const [formValues, setFormValues] = useState({
        nome: '',
        whatsapp: '',
        email: '',
        cidade: ''
    })

    async function fetchContato(id) {
        const contato = await getContato(chave, id).then(
            (response) => {
                return response
            },
            (error) => {
                // console.log('Erro ao recuperar contato: ', error)
                alert(`Erro ao recuperar contato: ${error}`)
                return null
            }
        )
        return contato
    }

    async function addContato(dados) {
        const novoContato = await newContato(dados, chave).then(
            (response) => {
                // console.log('response no addContato: ', response)
                return response
            },
            (erro) => {
                // console.log('Erro ao incluir contato: ', { erro })
                alert(`Erro ao incluir contato: ${erro}`)
                return null
            }
        )
        return novoContato
    }

    async function saveContatoAddFunil(dados) {
        const contatoId = await addContato(dados)
        const contatosAtualizados = await fetchContatos()

        const contato = await fetchContato(contatoId.id)

        await addCard(contato).then(
            (respCard) => {
                fetchListas();
                setAlert({ show: true, message: 'Cliente adicionado com sucesso ao funil!', severity: 'success' });
            },
            (erroCard) => {
                setAlert({ show: true, message: `O cliente potencial foi criado, mas ocorreu um erro ao adicionar o potencial cliente ao funil: ${erroCard}`, severity: 'error' });
            }
        )
    }

    const handleContatoSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(data.entries());

        var whatsapp = formJson['whatsapp'].replace(/[^0-9]/g, '')
        whatsapp = parseInt(whatsapp)
        formJson['whatsapp'] = whatsapp
        formJson['assinante'] = perfil.assinante

        // console.log('formJson: ', formJson)
        saveContatoAddFunil(formJson)

        setFormValues({
            nome: '',
            whatsapp: '',
            email: '',
            cidade: ''
        });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value
        });
      };

    return (
        <Box
            component="form"
            onSubmit={handleContatoSubmit}
            sx={{
                '& .MuiTextField-root': { m: 2, width: '100%' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '2px solid #fff',
                // margin: 2,
                padding: 2,
                mt: 2,
                mb: 2,
                backgroundColor: 'snow',
                borderRadius: 3,
            }}
            // noValidate
            autoComplete="off"
        >
            <Typography variant="subtitle2" gutterBottom>
                Novo Cliente Potencial
            </Typography>
            <TextField
                label="Nome"
                id='nome'
                name='nome'
                variant="standard"
                InputProps={{
                    startAdornment: (
                        <AccountCircle sx={{ color: 'action.active', mr: 1 }} />
                    ),
                }}
                fullWidth
                required
                onChange={handleChange}
                value={formValues.nome}
            />
            <TelefoneEdit
                label="WhatsApp"
                id='whatsapp'
                name='whatsapp'
                variant="standard"
                fullWidth
                required
                value={formValues.whatsapp}
                onChange={handleChange}
            />
            <TextField
                label="Email"
                id='email'
                name='email'
                variant="standard"
                type='email'
                InputProps={{
                    startAdornment: (
                        <EmailIcon sx={{ color: 'action.active', mr: 1 }} />
                    ),
                }}
                fullWidth
                required
                onChange={handleChange}
                value={formValues.email}
            />
            <TextField
                label="Cidade"
                variant="standard"
                id='cidade'
                name='cidade'
                InputProps={{
                    startAdornment: (
                        <LocationCity sx={{ color: 'action.active', mr: 1 }} />
                    ),
                }}
                fullWidth
                required
                onChange={handleChange}
                value={formValues.cidade}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='contained' type='submit' endIcon={<SendIcon />} sx={{ marginTop: 1 }} size='small'>
                    Salvar e Adicionar
                </Button>
            </Box>
        </Box>
    )
}