import React from "react";
import ContatosList from "../components/ContatosList";
import { useLocation } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import HomeBar from "../components/HomeBar/HomeBar";


function Contatos() {

    var nome = null
    var token = null
    var profile = null
    var isAuthenticated = false

    const location = useLocation()
    // console.log('location em contatos: ', location)
    const { state } = location

    if (state) {
        nome = state.nome
        token = state.token
        profile = state.profile
        isAuthenticated = state.isAuthenticated

        // console.log(nome, token, profile, isAuthenticated)
    }

    return (
        <>
            <HomeBar nome={nome} token={token} profile={profile} isAuthenticated={isAuthenticated} />
            <Container sx={{ marginTop: 5, paddingTop: '69px' }}>
                <Typography variant="h4" gutterBottom>
                    Potenciais Clientes
                </Typography>
                <ContatosList nome={nome} token={token} profile={profile} isAuthenticated={isAuthenticated} />
            </Container>
        </>
    )
}

export default Contatos