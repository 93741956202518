import React from 'react';
import { IconButton } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const WhatsAppLink = ({ phoneNumber }) => {
  const whatsappUrl = `https://wa.me/55${phoneNumber}`;

  return (
    <IconButton 
      // color="primary" 
      component="a" 
      href={whatsappUrl} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <WhatsAppIcon sx={{ color: 'green' }}/>
    </IconButton>
  );
};

export default WhatsAppLink;
