import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';


const FunilClienteAutoComplete = ({ items, setItem }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <Box>
      <Autocomplete
        options={items}
        getOptionLabel={(option) => option.nome}
        renderInput={(params) => <TextField {...params} label="Potencial cliente" fullWidth />}
        onChange={(event, newValue) => {
          setSelectedItem(newValue);
          setItem(newValue);
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option.id}>
            {option.nome}
          </Box>
        )}
      />
    </Box>
  );
};

export default FunilClienteAutoComplete;
