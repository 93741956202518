import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const contatoAPI = axios.create({ baseURL: `${baseAPI}api/funil/contatos` })

async function getContatos(token) {

    const authToken = `Token ${token}`
    // console.log('authToken: ', authToken)
    const resp = await contatoAPI.get('/', {headers: { Authorization: authToken }})

    return resp.data
}

async function getContato(token, id) {
    const authToken = `Token ${token}`
    const resp = await contatoAPI.get(`/${id}`, {headers: { Authorization: authToken }})
    return resp.data
}

async function newContato(dados, token) {
    const authToken = `Token ${token}`
    const resp = await contatoAPI.post('/', dados, {headers: { Authorization: authToken, "Content-Type": "application/json"}})
    return resp.data
}

async function updContato(dados, token) {
    const authToken = `Token ${token}`
    const resp = await contatoAPI.put(`/${dados.id}`, dados, {headers: { Authorization: authToken, "Content-Type": "application/json"}})
    return resp.data
}

async function delContato(pk, token) {
    const authToken = `Token ${token}`
    const resp = await contatoAPI.delete(`/${pk}`, {headers: { Authorization: authToken }})
    return resp.data
}

export {
    getContatos, newContato, updContato, delContato, getContato
}