import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const CurrencyTextField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                if (onChange) {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }
            }}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            prefix="R$"
            decimalScale={2}
            fixedDecimalScale
            customInput={TextField}
        />
    );
};

export default CurrencyTextField;