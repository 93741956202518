import {React, useRef, useState} from 'react'
import HomeBar from '../components/HomeBar/HomeBar'
import { Alert, Box, Button, Container, Snackbar, TextField, Typography } from '@mui/material'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { setPassword } from '../services/SrvLogin'


export default function AlterarSenha() {
    var autenticado = false
    var chave = null
    var perfil = null

    const location = useLocation()
    const { state } = location
    if (state) {
        autenticado = state.isAuthenticated
        chave = state.token
        perfil = state.profile
    }

    const [senha, setSenha] = useState('')
    const [confirma, setConfirma] = useState('')
    const [erroSenha, setErroSenha] = useState(false)
    const [helperSenha, setHelperSenha] =useState('')
    const [snackSuccessOpen, setSnackSuccessOpen] = useState(false)
    const [snackFailedOpen, setSnackFailedSOpen] = useState(false)
    const [erro, setErro] = useState('')

    const senhaRef = useRef()
    const confirmaRef = useRef()

    const navigate = useNavigate()

    const handleSubmit = (event) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(data.entries());

        const dados = {
            'old_password': formJson['senhaantiga'],
            'new_password': formJson['senha'],
        }

        setPassword(dados, chave).then(
            (response) => {
                setSnackSuccessOpen(true)                
            }, 
            (error) => {
                setSnackFailedSOpen(true)
                setErro(`Ocorreu um erro ao cadastrar o usuário: ${error['response']['data']}`)
                // console.log('Erro ao incluir novo usuário: ', error)
            }
        ) 
    }

    const handleSenhaChange = e => {

        if (e.target.name === 'senha') {
            setSenha(e.target.value)
        } else {
            setConfirma(e.target.value)
        }

        if (senha !== confirma) {
            // console.log('campos de senha diferentes')
            setErroSenha(true)
            setHelperSenha('Senha e confirmação devem ser iguais')
        } else {
            // console.log('campos de senha iguais')
            setErroSenha(false)
            setHelperSenha('')
        }
    }

    const handSuccessClose = e => {
        navigate('/home')
    }

    const handErrorClose = e => {
        setSnackFailedSOpen(false)
    }

    return (
        <>
            <HomeBar nome={perfil.nome} token={chave} profile={perfil} isAuthenticated={autenticado}/>
            <Container component='main' maxWidth='xs'>
            <Box sx={{ marginTop:8, display:'flex', flexDirection:'column', alignItems:'center' }}>
                <Typography component='h1' variant="h5">
                    Alterar Senha
                </Typography>
                <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField required label='Senha antiga' id="senhaantiga" name="senhaantiga"
                        type="password" 
                        margin="normal" 
                        fullWidth  
                    />
                    <TextField required label='Nova senha' id="senha" name="senha" ref={senhaRef} 
                        type="password" 
                        margin="normal" 
                        fullWidth  
                        onBlur={handleSenhaChange}
                        onChange={handleSenhaChange}
                        onKeyUp={handleSenhaChange}
                        error={erroSenha}
                        helperText={helperSenha}
                    />
                    <TextField required label="Confirme a nova senha" ref={confirmaRef} id="confirma" name="confirma" 
                        type="password" 
                        margin="normal" 
                        fullWidth  
                        onBlur={handleSenhaChange}
                        onChange={handleSenhaChange}
                        onKeyUp={handleSenhaChange}
                        error={erroSenha}
                        helperText={helperSenha}
                    />                    
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 2 }} >Ir</Button>
                    <Snackbar open={snackSuccessOpen} autoHideDuration={6000} onClose={handSuccessClose}>
                        <Alert
                            onClose={handSuccessClose}
                            severity="success"
                            variant="filled"
                            sx={{width: '100%'}}
                        >
                            Senha alterada com sucesso!
                        </Alert>
                    </Snackbar>
                    <Snackbar open={snackFailedOpen} autoHideDuration={6000} onClose={handErrorClose}>
                        <Alert
                            severity="error"
                            variant="filled"
                            sx={{width: '100%'}}
                        >
                            {erro}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Container>
        </>
    )
}