import { Box } from "@mui/material"
import React from "react"

const Biblioteca = () => {
    return (
        <Box component='main' display='flex' flexGrow={1}>
            <Box sx={{ maxWidth: 200, backgroundColor: 'red' }}>
                Box da Esquerda
            </Box>
            <Box sx={{ backgroundColor: 'blue' }}>
                Box do Centro
            </Box>
        </Box>
    )
}

export default Biblioteca