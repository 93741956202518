import React, { useEffect, useState } from 'react';
import { DndProvider, } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Button, Alert, AlertTitle } from '@mui/material';
import HomeBar from '../components/HomeBar/HomeBar';
import { useLocation } from 'react-router-dom';
import FunilClienteAutoComplete from '../components/FunilClienteAutoComplete';
import SendIcon from '@mui/icons-material/Send';
import { adicionarHorasUteis } from '../helpers/datehelper';
import Lista from '../components/Funil/Lista';
import { getContatos } from '../services/SrvContato';
import { getListas, initListas } from '../services/SrvLista';
import { getCards, newCard, updCard } from '../services/SrvCard';
import ContatoForm from '../components/Funil/ContatoForm';


const ItemTypes = {
    CARD: 'card',
};


const Funil = () => {

    const [contatoSelecionado, setContatoSelecionado] = useState(null)
    const [contatos, setContatos] = useState(null)
    const [addContatoBtnDisabled, setAddContatoBtnDisabled] = useState(true)
    const [alert, setAlert] = useState({ show: false, severity: '', message: '' });
    const [listas, setListas] = useState([])

    var autenticado = false
    var chave = null
    var perfil = null

    const location = useLocation()
    const { state } = location

    if (state) {
        chave = state.token
        perfil = state.profile
        autenticado = state.isAuthenticated
    }

    async function fetchListas() {
        await getListas(chave)
            .then((response) => {
                // console.log('No fetchListas: ', response)
                if (response.length === 0) {  // não há listas criadas ainda para o assinante
                    initializeListas()
                } else {
                    setListas(response)
                }
            },
                (error) => {
                    // console.log('Erro ao recuperar listas: ', error)
                    alert(`Erro ao recuperar listas: ${error}`)
                })
    }

    async function fetchContatos() {
        await getContatos(chave)
            .then((response) => {
                // console.log('response no fetchContatos: ', response)
                setContatos(response)
            },
                (error) => {
                    // console.log('Erro ao recuperar contatos: ', error)
                    alert(`Erro ao recuperar contatos: ${error}`)
                }
            )
    }

    async function initializeListas() {
        await initListas(chave)
            .then((response) => {
                setListas(response)
                setAlert({ show: true, message: 'Funil criado com sucesso!', severity: 'success' })
            },
                (error) => {
                    // console.log('Erro ao inicializar funil', error)
                    setAlert({ show: true, message: `Erro ao criar funil: ${error.response.data}`, severity: 'error' })
                })
    }

    useEffect(() => {
        fetchContatos()
        fetchListas()
        // eslint-disable-next-line
    }, [])

    async function fetchCards() {
        return await getCards(chave)
    }

    const handleAddContatoClick = () => {

        if (contatoSelecionado) {
            fetchCards()
                .then((response) => {
                    const cards = response
                    if (cards.length > 0) {
                        const contatoCards = cards.filter(card => card.contato === contatoSelecionado.id)
                        if (contatoCards.length > 0) {
                            setAlert({ show: true, message: 'O cliente potencial já está no funil', severity: 'warning' })
                        } else {
                            addCard(contatoSelecionado)
                        }
                        // console.log('cards: ', cards)
                    } else {
                        addCard(contatoSelecionado);
                    }
                }, (error) => {
                    // console.log('erro ao recuperar cards: ', error)
                    alert(`Erro ao recuperar cards: ${error}`)
                })
        }
    }

    const handleContatoSelecionado = (item) => {
        setContatoSelecionado(item)
        setAddContatoBtnDisabled(false)
    }

    async function setCard(card) {
        const cardAtualizado = await updCard(chave, card).then(
            (response) => {
                return response
            },
            (erro) => {
                // console.log('Ocorreu um erro ao atualizar o card: ', erro)
                alert(`Ocorreu um erro ao atualizar o card: ${erro}`)
                return null
            }
        )

        return cardAtualizado
    }

    async function addCard(contatoSelecionado) {
        const data_atual = new Date();
        const horas_expiracao = listas[0].horas_expiracao;
        const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);
        const dados = {
            'contato': contatoSelecionado.id,
            'lista': listas[0].id,
            'data_lista': new Date(),
            'data_expiracao': data_expiracao,
            'observacoes': null
        };
        await newCard(chave, dados).then(
            (response) => {
                fetchListas();
                setAlert({ show: true, message: 'Cliente adicionado com sucesso ao funil!', severity: 'success' });
            },
            (error) => {
                setAlert({ show: true, message: `Erro ao adicionar o potencial cliente ao funil: ${error}`, severity: 'error' });
            }
        );
    }

    

    const moveCard = (sourceIndex, sourceListaId, destListaId) => {
        const sourceLista = listas.find((lista) => lista.id === sourceListaId);
        // console.log('sourceLista: ', sourceLista)
        const destLista = listas.find((lista) => lista.id === destListaId);
        // console.log('destLista: ', destLista)

        if (sourceLista && destLista) {
            const [card] = sourceLista.cards.splice(sourceIndex, 1);

            if (card) {
                const data_atual = new Date();
                const horas_expiracao = destLista.horas_expiracao;
                const data_expiracao = adicionarHorasUteis(data_atual, horas_expiracao);

                card.lista = destListaId
                card.data_lista = data_atual
                card.data_expiracao = data_expiracao
                setCard(card).then(
                    (response) => {
                        fetchListas()
                    }
                )
            }
        }

        // destLista.cards.push(card);
        // setListas([...listas]);
    };

    return (
        <>
            {alert.show && (
                <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, show: false })} sx={{ paddingTop: '69px' }}>
                    <AlertTitle>{alert.severity === 'success' ? 'Sucesso' : 'Erro'}</AlertTitle>
                    {alert.message}
                </Alert>
            )}
            <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', paddingTop: alert.show ? '0px': '69px' }}>
                <HomeBar nome={perfil.nome} token={chave} profile={perfil} isAuthenticated={autenticado} />
                <Box component='main' display='flex' flexGrow={1}>
                    <Box sx={{ minWidth: 250, maxWidth: 300, backgroundColor: 'whitesmoke', padding: 2 }}>
                        {
                            contatos && (
                                <>
                                    <FunilClienteAutoComplete items={contatos} setItem={handleContatoSelecionado} />
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button variant='contained' endIcon={<SendIcon />} sx={{ marginTop: 1 }} size='small' disabled={addContatoBtnDisabled} onClick={handleAddContatoClick}>
                                            Adicionar ao funil
                                        </Button>
                                    </Box>
                                </>
                            )
                        }

                        <ContatoForm 
                            chave={chave} 
                            perfil={perfil} 
                            setAlert={setAlert} 
                            fetchListas={fetchListas} 
                            fetchContatos={fetchContatos} 
                            addCard={addCard} 
                        />

                    </Box>
                    {/* <Box sx={{ backgroundColor: '#1D7AFC', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', width: '100%' }}> */}
                    <Box sx={{ backgroundColor: '#f0e6fb', display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                        <DndProvider backend={HTML5Backend}>

                            {

                                listas.map((lista) => (
                                    <Lista key={lista.id} lista={lista} moveCard={moveCard} token={chave} fetchListas={fetchListas} />
                                ))
                            }

                        </DndProvider>
                    </Box>
                </Box>
            </Box>
        </>

    );
};

export { Funil, ItemTypes };


