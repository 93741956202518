import React from 'react';
import { Navigate, useLocation } from "react-router-dom"
import HomeBar from '../components/HomeBar/HomeBar';


function Home() {

var autenticado = false
var chave = null
var perfil = null

const location = useLocation()
const { state } = location
if (state) {
  autenticado = state.isAuthenticated
  chave = state.token
  perfil = state.profile
}

// console.log(autenticado, chave, perfil)

return (
  <>
    {
      autenticado ? (
        <HomeBar nome={perfil.nome} token={chave} profile={perfil} isAuthenticated={autenticado}/>
      ) : (
        <Navigate to={'/login'} />
      )
    }
  </>
  )
}

export default Home;