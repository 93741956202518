import axios from "axios";


const baseAPI = process.env.REACT_APP_BASE_API_URL
const listaAPI = axios.create({ baseURL: `${baseAPI}api/funil/listas` })


async function getListas(token) {
    const authToken = `Token ${token}`
    const resp = await listaAPI.get('/', { headers: { Authorization: authToken } })
    return resp.data
}


async function initListas(token) {
    const authToken = `Token ${token}`
    const resp = await listaAPI.get('/init/', { headers: { Authorization: authToken } })
    return resp.data
}

export {
    getListas,
    initListas
}