import React from 'react';
import MaskedInput from 'react-text-mask';
import TextField from '@mui/material/TextField';
import { Phone } from '@mui/icons-material';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(maskedInputRef) => {
        if (typeof inputRef === 'function') {
          inputRef(maskedInputRef ? maskedInputRef.inputElement : null);
        } else if (inputRef) {
          inputRef.current = maskedInputRef ? maskedInputRef.inputElement : null;
        }
      }}
      mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
});

// export default function TelefoneEdit(props) {
//     const { label, id, name, variant, onBlur, ...other } = props;
//     

const TelefoneEdit = ({ label, id, name, value, variant, onChange, ...other }) => {

  const [values, setValues] = React.useState({
    [name]: '',
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <TextField
      label={label}
      value={value}
      onChange={(event) => {
        onChange(event);
        handleChange(event);
      }}
      name={name}
      id={id}
      variant={variant}
      InputProps={{
        inputComponent: TextMaskCustom,
        startAdornment: (
          <Phone sx={{ color: 'action.active', mr: 1 }} />
        ),
      }}
      {...other}
    />
  );
}

export default TelefoneEdit;
